<template>
    <div class="page-store-invite-202404">
        <headeBar left-arrow :color="header_color" :title="header_title" :background="header_background"
            :opacity="header_opacity" :placeholder="false" @click-left="newAppBack" />
        <div class="headbg">
            <div class="djs">
                活动倒计时{{ day }}天
            </div>
        </div>
        <div class="invite-content">
            <div class="to-reward-list" @click="torewardlist">已邀请{{ inviteNum }}人<van-icon name="arrow" /> </div>

            <div class="invite-box">
                <div class="sub1" @click="toShare(1)">
                    <img src="https://img.chaolu.com.cn/ACT/store-invite-leduhui/wechat.png" alt="">
                    邀请好友
                </div>
                <div class="sub2" @click="toShare(2)">
                    <img src="https://img.chaolu.com.cn/ACT/store-invite-leduhui/picture.png" alt="">
                    生成分享海报
                </div>
            </div>
        </div>
        <div class="prize-box">
            <div class="prize-title">可领<span>{{ receiveNum }}</span>个</div>
            <div class="prize-list row-start-start">
                <div class="prize-item" v-for="item in 8">
                    <img :src="rewardMap[item]&&rewardMap[item][0].rewardImg" alt="">
                    <div class="row-center-center">
                        <h4 class="ellipsis-2">{{ rewardMap[item]&&rewardMap[item][0].rewardName }}</h4>
                    </div>
                    <p class="row-center-center" :class="{gray: !['CAN_RECEIVE', 'TASK_FULFILLED'].includes(rewardMap[item]&&rewardMap[item][0].status)}" @click="getReward(rewardMap[item]&&rewardMap[item][0])">{{ getName(rewardMap[item]&&rewardMap[item][0].status, 1) }}</p>
                </div>
            </div>
<!--            <p class="prize-tips">-->
<!--                每个奖励额外赠送1个冰箱贴盲盒-->
<!--                <span>集齐8个再得发财包</span>-->
<!--            </p>-->
<!--            <div class="prize-extra row-between-center">-->
<!--                <div class="row-start-center">-->
<!--                    <img :src="rewardMap[9]&&rewardMap[9][0].rewardImg" alt="">-->
<!--                    <div>-->
<!--                        <h4>{{ rewardMap[9]&&rewardMap[9][0].rewardName }}</h4>-->
<!--                        <p class="row-center-center" :class="{'gray1': rewardMap[9]&&rewardMap[9][0].status === 'RECEIVED', 'gray': rewardMap[9]&&rewardMap[9][0].status === 'TASK_FULFILLED'}" @click="getReward(rewardMap[9]&&rewardMap[9][0], true)">{{ getName(rewardMap[9]&&rewardMap[9][0].status) }}</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <img class="refrigerator" src="https://img.chaolu.com.cn/ACT/store-invite-leduhui/bg_h.png" alt="">-->
<!--            </div>-->
        </div>

        <div class="draw-box">
            <div class="top-tip-border" v-if="eventNumberInfo.show">
                <van-swipe :width="158" class="top-tip-box" :autoplay="3000"  :loop="true" :show-indicators="false" :touchable="false">
                    <van-swipe-item class="top-tip-item" v-for="(item, index) in rollUserList" :key="index">
                        <div class="row-start-center">
                            <img :src="item.img" alt="">
                            <div class="ellipsis">{{ item.message }}</div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>

            <div class="draw-btn row-between-center">
                <div class="row-center-center" :class="{banEvent: drawInfo.count <= 0}" @click="drawFun()">立即抽奖*{{ drawInfo.count || 0 }}</div>
                <div class="row-center-center" @click="toShare(1)">邀请获得次数</div>
            </div>
        </div>

        <div class="carve-box">
            <div class="gift-card row-between-center">
                <div :class="{active: inviteNum >= 2}">
                    <h4><span class="s1">10000</span><span class="s2">元</span></h4>
                    <p>礼品卡</p>
                </div>
                <div :class="{active: inviteNum >= 5}">
                    <h4><span class="s1">5000</span><span class="s2">元</span></h4>
                    <p>礼品卡</p>
                </div>
                <div :class="{active: inviteNum >= 7}">
                    <h4><span class="s1">5000</span><span class="s2">元</span></h4>
                    <p>礼品卡</p>
                </div>
            </div>
            <div class="user-ratio">已邀请 {{inviteNum}}/7</div>
            <div class="progress-box">
                <div class="bar"><div class="active-bar" :style="{width: getBarWidth()}"></div></div>
                <div class="row-between-center dot">
                   <div :class="{active: inviteNum >= 2}"></div>
                   <div :class="{active: inviteNum >= 5}"></div>
                   <div :class="{active: inviteNum >= 7}"></div>
                </div>
            </div>
            <div class="row-between-center">
                <div class="carve-msg" :class="{active: inviteNum >= 2}">
                    <p>邀请第2人</p>
                    <div>
                        <h4>{{getMoneyStr(eventNumberInfo.geTwo, 2, 10000)}}</h4>
                        <span v-if="eventNumberInfo.show">{{ eventNumberInfo.geTwo }}人参与瓜分</span>
                        <span v-else>邀请好友 参与瓜分</span>
                    </div>
                </div>
                <div class="carve-msg" :class="{active: inviteNum >= 5}">
                    <p>邀请第5人</p>
                    <div>
                        <h4>{{getMoneyStr(eventNumberInfo.geFive, 5, 5000)}}</h4>
                        <span v-if="eventNumberInfo.show">{{ eventNumberInfo.geFive }}人参与瓜分</span>
                        <span v-else>邀请好友 参与瓜分</span>
                    </div>
                </div>
                <div class="carve-msg" :class="{active: inviteNum >= 7}">
                    <p>邀请第7人</p>
                    <div>
                        <h4>{{getMoneyStr(eventNumberInfo.geSeven, 7, 5000)}}</h4>
                        <span v-if="eventNumberInfo.show">{{ eventNumberInfo.geSeven }}人参与瓜分</span>
                        <span v-else>邀请好友 参与瓜分</span>
                    </div>
                </div>
            </div>
            <p class="carve-tips">活动结束后，3个工作日内发放至您的账户中</p>
            <div class="row-center-center carve-btn" @click="toShare(1)">立即邀请好友  参与瓜分</div>
        </div>

        <div class="rule-box">
            <img src="https://img.chaolu.com.cn/ACT/store-invite/rules1.png" alt />
        </div>
        <van-popup v-model="showGet" position="center" class="pop-get">
            <div class="pop-get-title">
                奖品领取确认
            </div>
            <div class="pop-get-tips">
                领取当前的奖品后，
            </div>
            <div class="pop-get-tips">
                将无法再领取同等级的奖品哦
            </div>
            <div class="jp">
                <img :src="getObj.rewardImg" />
            </div>
            <div class="pop-get-sub" @click="sureReward">确认领取</div>
            <div class="noget" @click="showGet = false">我再想想</div>
        </van-popup>

        <van-popup v-model="showDraw" position="center" style="background: transparent">
            <div class="pop-get pop-draw">
                <div class="pop-get-title">恭喜抽中奖品</div>
                <div class="pop-get-tips">{{ prizeInfo.prizeName }}</div>

                <div class="jp">
                    <img :src="prizeInfo.picUrl" />
                </div>
                <div class="pop-get-sub" @click="showDraw = false; toShare(1)">分享活动</div>
                <div class="noget">可在 我的＞礼品卡 查看奖品</div>
            </div>
            <img @click="showDraw = false" class="result-close" src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/double11-2021/result-close.png" alt="">
        </van-popup>


        <van-popup v-model="showCollectionRecords" position="bottom" safe-area-inset-bottom class="pop">
            <div class="pop-title">
                领取记录
                <van-icon name="cross" class="pop-close" color="#fff" @click="showCollectionRecords = false" />
            </div>
            <div class="collection-records">
                <div class="mode2" v-if="getObj.needAddress">
                    <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/map.png"
                        alt="" class="maplogo" />
                    <div class="pub_onefull" v-if="getObj.addressVO">
                        <div class="line1">{{ getObj.addressVO.province + getObj.addressVO.city + getObj.addressVO.district
                        }}
                        </div>
                        <div class="line2">{{ getObj.addressVO.addressDetail }}</div>
                        <div class="line3">{{ getObj.addressVO.name }} <span class="phone">{{ getObj.addressVO.phone
                        }}</span> </div>
                    </div>
                </div>
                <div class="mode1" v-else>已发放至您的优惠券账户，<br />可到线下超鹿门店找教练核销哦</div>
                <div class="jg"></div>
                <div class="records-box">
                    <div class="records-model">
                        <div class="records-img">
                            <img :src="getObj.rewardImg" alt="" />
                        </div>
                        <div class="records-info">
                            <div class="records-name">{{ getObj.rewardName }}</div>
                            <div class="records-tips" v-if="getObj.needAddress">确认发货后将进行短信告知</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-overlay :show="isShareWx" :z-index="1500" @click="isShareWx = false">
            <div class="tip-box" @click.stop>
                <img src="https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/anniversary2021/share-mini.png"
                    class="tip-img" />
            </div>
        </van-overlay>

        <InviteList ref="refInviteList" />
        <common-share :value="showShare" :shareParams="shareParams" @close="showShare = false"
            @share-success="showShare = false" :shareItem="['posterfriend', 'postermessage', 'save']"
            :miniSharePoster="appTypeStr === 'mini'">
            <template slot="createDom">
                <div class="canvascss2">
                    <img src="https://img.chaolu.com.cn/ACT/store-invite/poster1.png" class="canvascss_bg" />
                    <div class="ul">
                        <img class="ucode" :src="userHeadImg" />
                        <div class="uinfo ellipsis">
                            <div class="u1 ellipsis">{{ nickName }}</div>
                            <!-- <div class="u2">推荐你来超鹿上团课开启燃<br />脂、飙汗的团课之旅~</div> -->
                        </div>
                        <div class="wai">
                            <img class="nei" :src="userPoster" />
                        </div>
                    </div>
                </div>
            </template>
        </common-share>
    </div>
</template>
<script>
import {initBack, newAppBack, saveCreateImg, webAppShare} from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import commonShare from '@/components/commonShare';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';
import headerMixin from '@/mixin/headerMixin';
import InviteList from './components/inviteList.vue';

const shareUrl = '/pages/subPages/experience/index';
const inviteRewardsType = "VENUE_NEW_USER_REWARED_FOR_INVITER";
const inviteType = "VENUE_NEW_USER";
const venueId = 89;
const activityNo = '2024baolong';
const storeName = '宝龙城市广场'

export default {
    components: {
        headeBar,
        commonShare,
        InviteList
    },
    mixins: [userMixin, headerMixin],
    data() {
        return {
            showCollectionRecords: false,//显示领取记录弹窗
            showDraw: false,//抽奖弹窗
            userHeadImg: '',
            nickName: '',
            userPoster: '',
            isShareWx: false,
            rewardMap: {},//奖品等级
            rewardMapLast: {},//最后一个奖品
            finished: false,
            showShare: false, // 是否显示分享弹窗
            showGet: false,//领取奖品弹窗
            shareParams: {
                title: `${storeName}店，欢迎新朋友`,
                content: `${storeName}店，欢迎新朋友`,
                multiple: '1',
                userName: 'gh_7f5b57b6a76e',
                miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2',
                url: 'url',
                miniImage: 'https://img.chaolu.com.cn/ACT/store-invite/mini1.png',
                path: shareUrl,
            },
            inviteNum: 0,
            receiveNum: 0,
            day: 0,
            drawInfo: {}, // 抽奖次数信息
            prizeInfo: {}, // 中奖信息
            getObj: {},//确认奖品展示

            rollUserList: [],
            eventNumberInfo: {},
        }
    },
    methods: {
        saveCreateImg,
        newAppBack,
        sureReward() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            this.$axios
                .post(this.baseURLApp + "/schedule/invitation/manualReceive", {
                    inviteRewardsType,
                    inviteType,
                    venueId,
                    rewardId: this.getObj.rewardId,
                    userId: this.userId,
                    token: this.token
                }).then((res) => {
                    this.$toast.clear();
                    this.showGet = false;
                    this.$toast('领取成功');
                    this.getRewardList();
                }).catch(() => {
                    this.close();
                });
        },
        close() {
            this.$toast.clear()
            this.$dialog.alert({
                title: '活动已结束',
                message: '',
                overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
            }).then(() => {
                if (this.appTypeStr == 'mini') {
                    wx.miniProgram.switchTab({ url: '/pages/home/index' });
                } else {
                    this.newAppBack();
                }
            })
        },
        getReward(item, isExtra = false) {
            if(!isExtra && ['CAN_RECEIVE', 'TASK_FULFILLED'].includes(item.status) && this.receiveNum <= 0){
                this.$toast(`可领取次数为0，快去邀请新用户去${storeName}店运动吧`)
                return false
            }
            if (item.status === 'CAN_RECEIVE') {
                if (item.needAddress) {
                    const path=`/superdeer-activity/receive-reward/index?rewardId=${item.rewardId}&inviteRewardsType=${inviteRewardsType}&inviteType=${inviteType}&venueId=${venueId}`
                    this.$router.push(path)
                } else {
                    this.getObj = item;
                    this.showGet = true;
                }
            } else if (item.status === 'RECEIVED') {
                this.$axios.post(this.baseURLApp + "/schedule/invitation/getRewardDetail", {
                    inviteRewardsType,
                    inviteType,
                    venueId,
                    rewardId: item.rewardId,
                    userId: this.userId,
                    token: this.token
                }).then((res) => {
                    this.getObj = res.data;
                    this.showCollectionRecords = true;
                })
            }
        },

        torewardlist() {
            this.$refs.refInviteList.open();
        },
        // 邀请分享相关
        async toShare(type) {
            if (type === 1) {
                this.wxInvite();
            } else if (type === 2) {
                this.showShare = true;
            }
        },
        initQr() {
            if (!this.userId) return
            // const ps = {
            //     source: 'store-invite-wandalugang',
            //     webUrl: pageUrl,
            //     cityId: 1,
            //     userId: 1,
            //     inviteUid: this.userId,
            // }
            // console.log(ps);
            // return this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
            //     hasWx: true,
            //     userId: this.userId,
            //     token: this.token,
            //     width: 240,
            //     height: 240,
            //     webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
            //     scene: JSON.stringify(ps)
            // }).then((res) => {
            //     this.userPoster = 'data:image/png;base64,' + res.data.qrCode
            //     this.userHeadImg = res.data.headImg
            //     this.nickName = res.data.nickName
            // })
            this.$axios.post(this.baseURLApp + '/userInvita/queryInvitePoster', { userId: this.userId }).then((res) => {
                this.userPoster = 'data:image/png;base64,' + res.data.poster
                this.userHeadImg = res.data.headImg
                this.nickName = res.data.nickName
            })
        },
        wxInvite() {
            if (!this.userId) {
                this.$toast('请稍后再试');
                return;
            }

            if (this.appTypeStr === 'mini') {
                this.isShareWx = true
            } else {
                let params = {
                    scene: 0,
                    ...this.shareParams
                }
                let miniParams = JSON.parse(JSON.stringify(params))
                miniParams.image = miniParams.miniImage
                webAppShare('3', miniParams)
            }
        },

        // 处理状态中文名
        getName(v, type = 0) {
            const json = {
                'RECEIVED': '已领取',
                'RECEIVED_OTHER': '已领取其他奖品',
                'CAN_RECEIVE': '领取',
                'TASK_FULFILLED': type === 1? '领取': '未达成'
            }
            return json[v];
        },
        getMoneyStr(totalNum, reachNum=0, amount=0) {
            if(this.inviteNum < reachNum){
                return '未达成'
            }else if(this.eventNumberInfo.show){
                return `可瓜分${( parseFloat((amount/totalNum).toFixed(2)) )}元`
            }else {
                return '已达成'
            }
        },
        getBarWidth() {
            if(this.inviteNum <= 5){
                return (((this.inviteNum-2)/6)<0?0:((this.inviteNum-2)/6))*100 + '%'
            }else if(this.inviteNum <= 7){
                return (0.5+((this.inviteNum-5)/4)<0?0:(0.5+(this.inviteNum-5)/4))*100 + '%'
            }else {
                return '100%'
            }
        },
        //获取奖品详情
        getRewardList() {
            this.$axios.post(this.baseURLApp + "/venue/invitation/manualReceiveDetail", {
                inviteRewardsType,
                inviteType,
                userId: this.userId,
                token: this.token,
                venueId,
            }).then((res) => {
                const d = res.data;
                this.inviteNum = d.inviteNum;
                this.receiveNum = d.receiveNum;
                this.rewardMap = d.rewardMap
                this.day = d.activityDay;
            }).catch(() => {
                this.close();
            })
        },

        // 获取抽奖次数
        getParticipateData() {
            this.$axios.post(this.baseURLApp + "/anniversary/preheat/luckyWheel/getParticipateData", {
                inviteRewardsType,
                inviteType,
                activityNo,
                userId: this.userId,
                token: this.token,
                venueId,
            }).then((res) => {
                this.drawInfo = res.data
            })
        },
        // 抽奖
        drawFun() {
            if(this.drawInfo.activityActiveTimeCheck === 'NOT_START') {
                this.$toast('活动暂未开启！')
                return false
            }else if(this.drawInfo.activityActiveTimeCheck === 'END') {
                this.$toast('活动已结束！')
                return false
            }else if(this.drawInfo.count <= 0){
                this.$toast('暂无抽奖次数！')
                return false
            }
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            this.$axios.post(this.baseURLApp + "/anniversary/preheat/luckyWheel/luckDraw", {
                inviteRewardsType,
                inviteType,
                activityNo,
                userId: this.userId,
                token: this.token,
                venueId,
            }).then((res) => {
                this.getParticipateData()
                this.$toast.clear()
                this.showDraw = true
                this.prizeInfo = res.data
            })
        },
        // 获取瓜分人数
        getEventNumber() {
            this.$axios.post(this.baseURLApp + "/venue/invitation/eventNumber", {
                inviteRewardsType,
                inviteType,
                userId: this.userId,
                token: this.token,
                venueId,
            }).then((res) => {
                this.eventNumberInfo = res.data || []
                // this.eventNumberInfo.show = false
                this.rollUserList = res.data.awardInfo || []
            })
        },
    },
    mounted() {
        this.bindHeaderScroll(this.$el, 200, (scrollTop, morehalf) => {});
    },
    async created() {
        console.log(this.$route.query);
        initBack();
        await this.$getAllInfo(['userId', 'cityId']);
        this.getRewardList();
        this.getParticipateData();
        this.getEventNumber();
        this.initQr();
        this.shareParams.path = `${shareUrl}?inviteUid=${this.userId}`;
        wx.miniProgram.postMessage({
            data: {
                type: 'share',
                shareImage: this.shareParams.miniImage,
                shareTitle: this.shareParams.title,
                shareUrl: this.shareParams.path,
            },
        })
    }
}
</script>
<style lang="less" scoped>
.page-store-invite-202404 {
    background: #ffe8af;
    height: 100vh;
    overflow: auto;
    div {
        box-sizing: border-box;
    }
    img {
        vertical-align: top;
        width: 100%;
    }
    .banEvent {
        filter: grayscale(1);
        pointer-events: none;
    }
    .headbg {
        height: 1000px;
        background: url(https://img.chaolu.com.cn/ACT/store-invite/banner1.png) no-repeat center center / 100% 100%;
        //background-size: 100% 100%;
        //position: relative;
        padding-top: 732px;
    }
    .djs {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #1040AC;
        font-weight: bold;
        line-height: 24px;
        width: 264px;
        height: 58px;
        padding-bottom: 4px;
        background: url("https://img.chaolu.com.cn/ACT/store-invite-leduhui/time_bg.png") no-repeat center center / 100% 100%;
    }
    .prize-box{
        margin: 38px auto 0;
        width: 706px;
        height: 842px;
        background: url(https://img.chaolu.com.cn/ACT/store-invite-leduhui/lugang/bg_b.png) no-repeat center center / 100% 100%;
        padding: 28px 40px 36px;
        .prize-title{
            font-size: 24px;
            font-weight: bold;
            text-align: right;
            color: #fff;
            span{
                font-family: PuHuiTi;
                font-size: 34px;
                margin: 0 6px;
            }
        }
        .prize-list{
            padding-top: 12px;
            min-height: 712px;
            flex-wrap: wrap;
            .prize-item{
                width: 140px;
                margin-top: 50px;
                flex-shrink: 0;
                margin-right: 22px;
                &:nth-child(4n){
                    margin-right: 0;
                }
                img{
                    width: 140px;
                    height: 140px;
                }
                div{
                    height: 60px;
                    margin-top: 28px;
                    h4{
                        line-height: 30px;
                        color: #333333;
                        font-size: 24px;
                        text-align: center;
                        white-space: pre-line;
                    }
                }
                p{
                    width: 140px;
                    height: 52px;
                    margin-top: 20px;
                    background: #FF5D48;
                    border-radius: 8px;
                    font-size: 24px;
                    font-weight: bold;
                    color: #FFFFFF;
                    &.gray{
                        background: #FF8C26;
                        opacity: 0.6;
                    }
                }
            }
        }
        .prize-tips{
            font-size: 28px;
            font-weight: bold;
            color: #FFFFFF;
            text-align: center;
            margin-top: 66px;
            span{
                display: block;
                margin-top: 5px;
            }
        }
        .prize-extra{
            margin-top: 40px;
            padding: 0 6px;
            img{
                width: 140px;
                height: 140px;
                margin-right: 30px;
                &.refrigerator{
                    margin-right: 0;
                    width: 160px;
                    height: 134px;
                }
            }
            h4{
                color: #333333;
                font-size: 24px;
            }
            p{
                width: 140px;
                height: 52px;
                margin-top: 26px;
                background: #FF5D48;
                border-radius: 8px;
                font-size: 24px;
                font-weight: bold;
                color: #FFFFFF;
                &.gray{
                    background: #C3B2A4;
                    pointer-events: none;
                }
                &.gray1{
                    background: #FF8C26;
                    opacity: 0.6;
                }
            }
        }
    }
    .draw-box{
        margin: 38px auto 0;
        width: 706px;
        height: 745px;
        background: url(https://img.chaolu.com.cn/ACT/store-invite-leduhui/lugang/bg_c.png) no-repeat center center / 100% 100%;
        padding: 94px 40px 36px;
        position: relative;
        .draw-btn{
            width: 100%;
            padding: 0 40px;
            position: absolute;
            bottom: 46px;
            left: 0;
            font-family: PuHuiTi;
            font-size: 36px;
            color: #5F2600;
            div{
                width: 297px;
                height: 88px;
                background: linear-gradient(180deg, #FFF8D1 0%, #FFEC50 99%);
                box-shadow: 0 2px 8px 0 rgba(255,255,255,0.7);
                border-radius: 44px;
                &:last-child{
                    box-shadow: 0 2px 8px 0 rgba(255,255,255,0.7);
                    background: linear-gradient(0deg, #FFDEB8 0%, #FFF1EC 100%);
                }
            }

        }
    }
    .carve-box{
        margin: 38px auto 0;
        width: 706px;
        height: 740px;
        background: url(https://img.chaolu.com.cn/ACT/store-invite-leduhui/hs/bg_d.png) no-repeat center center / 100% 100%;
        padding: 28px 26px 36px;
        .gift-card{
            padding: 76px 0 0;
            div{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 204px;
                height: 160px;
                &.active{
                    p{
                        color: #A96700;
                        background: #FFE7A8;
                    }
                    h4{
                        color: #FE5922;
                    }
                }
                p{
                    width: 100px;
                    height: 36px;
                    background: #E4E7F1;
                    border-radius: 6px;
                    color: #6473A3;
                    font-size: 22px;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 8px;
                }
                h4{
                    color: #6473A3;
                    .s2{
                        font-family: PuHuiTi;
                        font-size: 24px;
                        position: relative;
                        top: -4px;
                        font-weight: 400;
                    }
                    .s1{
                        font-family: BebasNeueBold;
                        font-size: 50px;
                        font-weight: 400;
                    }
                }
            }
        }
        .user-ratio{
            margin-top: 16px;
            text-align: center;
            font-size: 30px;
            color: #FFED5B;
            font-family: PuHuiTi;
        }
        .progress-box{
            height: 26px;
            width: 476px;
            position: relative;
            margin: 18px auto;
            .bar{
                width: 460px;
                height: 10px;
                background: rgba(255,255,255,0.4);
                border-radius: 5px;
                position: absolute;
                top: 8px;
                left: 8px;
                .active-bar{
                    width: 20%;
                    height: 100%;
                    background-color: white;
                    border-radius: 5px;
                }
            }

            .dot{
                position: relative;
                z-index: 99;
                div{
                    width: 26px;
                    height: 26px;
                    background: #fff;
                    border: 6px solid #A0B3FA;
                    border-radius: 50%;
                    box-sizing: border-box;
                    &.active{
                        border-color: white;
                        background-color: #6081F8;
                    }
                }
            }
        }
        .carve-msg{
            width: 204px;
            &.active{
                h4{
                    color: #FE5922;
                }
            }
            p{
                font-weight: bold;
                text-align: center;
                color: #FFFFFF;
                font-size: 22px;
            }
            div{
                margin-top: 10px;
                width: 204px;
                height: 104px;
                background: url(https://img.chaolu.com.cn/ACT/store-invite-leduhui/bg_e.png) no-repeat center center / 100% 100%;
                padding-top: 30px;
                h4{
                    font-family: PuHuiTi;
                    font-size: 24px;
                    color: #6473A3;
                    text-align: center;
                    line-height: 1;
                    font-weight: 400;
                }
                span{
                    display: block;
                    text-align: center;
                    line-height: 1;
                    font-size: 18px;
                    color: #242831;
                    margin-top: 12px;
                }
            }
        }
        .carve-tips{
            text-align: center;
            color: white;
            font-weight: bold;
            line-height: 1;
            font-size: 22px;
            margin-top: 50px;
        }
        .carve-btn{
            margin: 22px auto 0;
            width: 630px;
            height: 90px;
            background: linear-gradient(180deg, #FFF8D1 0%, #FFEC50 99%);
            box-shadow: 0 2px 8px 0 rgba(255,255,255,0.7);
            border-radius: 45px;
            font-family: PuHuiTi;
            color: #333333;
            font-size: 36px;
        }
    }
    .new-info {
        font-size: 22px;
        color: #333333;
        text-align: center;
        font-weight: bold;
        margin-bottom: 67px;
        line-height: 1;
    }
    .invite-content{
        margin: -172px auto 0;
        width: 706px;
        height: 268px;
        background: url(https://img.chaolu.com.cn/ACT/store-invite-leduhui/lugang/bg_a.png) no-repeat center center / 100% 100%;
        padding: 34px 0 29px;
    }

    .invite-box {
        height: 104px;
        position: relative;
        display: flex;
        padding: 0 38px;
        justify-content: space-between;
        margin: 60px 0 0;
        &>div{
            width: 330px;
            height: 88px;
            background: linear-gradient(180deg, #FAB522 0%, #F83600 100%);
            box-shadow: 0 2px 8px 0 rgba(255,255,255,0.7);
            border-radius: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 36px;
            font-family: PuHuiTi;
            color: white;
            img{
                width: 46px;
                height: 46px;
                margin-right: 14px;
            }
        }
        .sub1 {
            width: 276px;
            height: 88px;
            background: linear-gradient(180deg, #7DB0FF 0%, #1D6FEB 100%);
        }
    }

    .to-reward-list {
        display: flex;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        align-items: center;
        justify-content: flex-end;
        padding-right: 38px;
    }

    .gift-list {
        background: #EAF5FF;
        margin: 0 24px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        .level-model {
            padding-bottom: 48px;

            .level-head {
                display: flex;
                align-items: center;

                .level-num {
                    background: url(https://img.chaolu.com.cn/ACT/store-invite-202312/coner.png);
                    background-size: 100% 100%;
                    width: 140px;
                    height: 64px;
                    line-height: 72px;
                    text-align: center;
                    color: #fff;
                    font-size: 28px;
                    position: relative;
                    top: -4px;
                    left: -8px;
                    margin-right: 14px;
                    font-weight: bold;
                }

                .finished {
                    font-size: 26px;
                    color: #FD3665;
                    font-weight: bold;
                    position: relative;
                    padding-left: 40px;
                    line-height: 1;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        margin-top: -15px;
                        width: 30px;
                        height: 30px;
                        background: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/finished.png);
                        background-size: cover;
                    }
                }

                .nofinished {
                    font-weight: bold;
                    font-size: 26px;
                    color: #242831;
                }
            }

            .level-body {
                display: flex;
                flex-wrap: wrap;
                padding: 0 28px;
                margin-top: 12px;
                .reward-model {
                    width: 204px;
                    height: 344px;
                    border-radius: 10px;
                    background: #fff;
                    text-align: center;
                    margin-top: 16px;

                    &:nth-child(3n+2) {
                        margin-left: 16px;
                        margin-right: 16px;
                    }

                    .level-img {
                        width: 140px;
                        height: 140px;
                        margin: 32px auto 16px;

                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }

                    .level-name-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 172px;
                        height: 72px;
                        margin: 0 auto;

                        .level-name {
                            font-size: 24px;
                            color: #432F17;
                            font-weight: bold;
                        }
                    }

                    .level-sub {
                        border-radius: 8px;
                        background: #61B2F6;
                        text-align: center;
                        height: 52px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 172px;
                        margin: 16px auto 0;
                        font-size: 24px;
                        color: #fff;
                        font-weight: bold;

                        .getimg {
                            width: 28px;
                            height: 28px;
                            margin-left: 16px;
                        }
                    }

                    .op {
                        opacity: 0.5;
                    }

                    .hasget {
                        font-size: 22px;
                    }
                }
            }
        }
    }

    .rule-box {
        margin: 38px 22px;
        padding-bottom: 100px;
    }

    .tip-box {
        position: relative;

        .tip-img {
            position: absolute;
            top: 7px;
            right: 130px;
            width: 300px;
        }
    }

    .pop-get {
        border-radius: 16px;
        width: 560px;
        height: 790px;
        background: linear-gradient(180deg, #E6FAFF 0%, #FBE6CA 100%);
        text-align: center;
        &.pop-draw{
            background: white;
            height: 720px;
            .pop-get-title{
                padding-top: 62px;
                margin-top: 0;
                color: #242831;
                line-height: 1;
            }
            .pop-get-tips {
                font-weight: normal;
                line-height: 30px;
                color: #FF4830;
                font-size: 36px;
                font-family: PuHuiTi;
            }
            .jp {
                margin-top: 60px;
                width: auto;
                img{
                    border-radius: 16px;
                    height: 220px;
                    display: block;
                    object-fit: cover;
                    width: auto;
                    margin: 0 auto;
                }
            }
            .pop-get-sub {
                width: 380px;
            }
            .noget {
                font-size: 24px;
            }
        }
        .pop-get-title {
            color: #822D13;
            font-weight: bold;
            font-size: 44px;
            margin-top: 64px;
            margin-bottom: 24px;
        }

        .pop-get-tips {
            color: #822D13;
            font-size: 24px;
            line-height: 36px;
        }

        .jp {
            width: 240px;
            margin: 70px auto;
        }

        .pop-get-sub {
            width: 360px;
            line-height: 96px;
            color: #fff;
            background: linear-gradient(to right, #FF7F37 0%, #FF3672 100%);
            margin: 0 auto 30px;
            border-radius: 10px;
            font-weight: bold;
        }

        .noget {
            font-size: 28px;
            color: #822D13;
        }
    }

    .result-close {
        width: 60px;
        height: 60px;
        margin: 48px auto 0;
        display: block;
    }
    .pop {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .pop-title {
            color: #432F17;
            font-weight: bold;
            background: linear-gradient(180deg, #FCF2DA 0%, #FFFFFF 100%);
            font-size: 40px;
            text-align: center;
            line-height: 120px;
            position: relative;

            .pop-close {
                position: absolute;
                right: 32px;
                top: 38px;
                width: 44px;
                height: 44px;
                line-height: 44px;
                border-radius: 50%;
                background: rgba(39, 40, 49, 0.2);
                font-size: 28px;
            }
        }

    }

    .collection-records {
        .mode1 {
            font-size: 26px;
            color: #5A0A00;
            text-align: center;
            padding: 32px 0;
        }

        .mode2 {
            display: flex;
            align-items: center;
            padding: 40px 32px 56px;
            position: relative;

            &::after {
                content: '';
                height: 4px;
                background-image: url(https://chaolucoachwebstatic.oss-cn-hangzhou.aliyuncs.com/static/img/group-invite-202304/border.png);
                background-size: cover;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .maplogo {
                width: 40px;
                height: 40px;
                margin-right: 20px;
            }

            .line1 {
                font-size: 24px;
                color: #666;
            }

            .line2 {
                color: #242831;
                font-size: 30px;
                font-weight: bold;
                margin: 20px 0;
            }

            .line3 {
                color: #242831;
                font-size: 26px;

                .phone {
                    color: #666;
                }
            }
        }

        .jg {
            height: 16px;
            background: #F5F5F5;
        }

        .records-box {
            min-height: 520px;
        }

        .records-model {
            display: flex;
            padding: 48px 32px 0;

            .records-img {
                width: 160px;
                height: 160px;
                margin-right: 40px;
            }

            .records-name {
                font-size: 28px;
                font-weight: bold;
                color: #242831;
                margin-bottom: 20px;
            }

            .records-tips {
                font-size: 22px;
                color: #666;
                line-height: 34px;
            }
        }
    }

    @bei: 1px;

    .canvascss2 {
        position: fixed;
        bottom: -200%;
        // top: 0;
        width: @bei*600;
        height: @bei*1079;
        margin: 0 auto;
        box-sizing: border-box;

        .canvascss_bg {
            width: 100%;
            height: 100%;
        }

        .ul {
            position: absolute;
            left: @bei*26;
            bottom: @bei*35;
            right: @bei*34;
            display: flex;
            color: #fff;

            .ucode {
                z-index: 1;
                width: @bei*100;
                height: @bei*100;
                border-radius: 50%;
                overflow: hidden;
                margin-right: @bei*20;
                margin-top: @bei*19;
            }

            .uinfo {
                flex: 1;
                margin-right: @bei*20;
                margin-top: @bei*18;

                .u1 {
                    font-size: @bei*26;
                    font-weight: bold;
                    color: #242831;
                    margin-bottom: @bei*10;
                }

                .u2 {
                    color: #242831;
                    font-size: @bei*20;
                }
            }

            .wai {
                border: @bei*6 solid #004FBC;
                border-radius: 50%;
                width: @bei*126;
                height: @bei*126;
                background: #fff;
                padding: @bei*5;
                overflow: hidden;

                .nei {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    /deep/.canvas-box {
        padding-left: 40px !important;
        padding-right: 40px !important;
        box-sizing: border-box;

        .create-img {
            width: 100% !important;
        }
    }
}
.top-tip-border {
    width: 632px;
    //padding: 0 12px;
    box-sizing: border-box;
    height: 32px;
}
.top-tip-box {
    height: 32px !important;
    .top-tip-item {
        img{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 10px;
        }
        div {
            font-size: 22px;
            font-weight: 400;
            white-space: nowrap;
            color: #FFFFFF;
        }
    }
}
</style>
